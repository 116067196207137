<template>
  <tr v-if="person && !failed">
    <td>{{ person.fullName }}</td>
    <td>{{ portions }}</td>
    <td>{{ coupons }} st</td>
    <td class="text-center">{{ half }}</td>
    <td class="text-center">{{ toGo }}</td>
    <td class="text-right">{{ when }}</td>
  </tr>
  <tr class="bg-danger" v-else-if="person && failed">
    <td>{{ person.fullName }}</td>
    <td class="text-center" colspan="5"><strong>MISSLYCKADES:</strong> {{ log.message }}</td>
  </tr>
</template>

<script>
  import { firebase } from "@/initializers/firebase.js"

  export default {
    name: "scan-table-row",
    props: {
      log: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        person: null,
        schedules: []
      }
    },
    computed: {
      failed() {
        return (typeof this.log.failed == "boolean" && this.log.failed == true)
      },
      half() {
        return this.log.half ? "Ja" : "Nej"
      },
      toGo() {
        return this.log.toGo ? "Ja" : "Nej"
      },
      portions() {
        if(this.log.portions) {
          let portions = parseInt(this.log.portions)
          return `${portions} ${portions == 1 ? 'portion' : 'portioner'}`
        } else {
          return `1 portion`
        }
      },
      coupons() {
        let result = "n/a"
        if(this.schedules.length > 0) {
          let currentSchedule = this.schedules[0]
          let when = this.$DateTime.fromISO(this.log.when)
          let schedule = currentSchedule.days[when.weekday]
          let portions = 1

          if(this.log.portions) {
            portions = parseInt(this.log.portions)
          }

          if(this.log.half) {
            result = (1 * portions)
          } else {
            result = (parseInt(schedule.coupons) * portions)
          }
        }

        return result
      },
      when() {
        return this.$DateTime.fromISO(this.log.when).toFormat("HH:mm:ss")
      }
    },
    async mounted() {
      await this.$bind("person", firebase.firestore().collection(`people`).doc(this.log.to))
      await this.$bind("schedules", firebase.firestore().collection(`schedules`).where("year", "==", this.$DateTime.local().year.toString()))
    }
  }
</script>