<template>
  <fullscreen class="home h-100" ref="fullscreen" @change="fullscreenChange" background="#8bacbd">
    <div class="logo-wrapper position-absolute text-center text-white cursor-pointer" @click="toggleFullscreen">
      <div class="logo-content bg-white rounded-circle border border-secondary">
        <img src="../../assets/tornhuset.png" class="logo m-1 rounded-circle" alt="Agnes">
      </div>
    </div>

    <scanner :scan-complete="scanComplete" :countdown-complete="countdownComplete" :seconds-to-wait="2" :voucher-cost="voucherCost" :show-voucher-cost="true" :voucher-cost-changed="voucherCostChanged"></scanner>

    <latest-scans></latest-scans>

    <button type="button" id="signOut" class="btn btn-danger position-absolute" @click="signOut" v-if="!fullscreen">Logga ut</button>
  </fullscreen>
</template>

<script>
  import { firebase } from "@/initializers/firebase.js"
  
  import sortBy from "lodash/sortBy"
  import Scanner from "@/frontend/components/scanner.vue"
  import LatestScans from "@/frontend/components/latest-scans.vue"

  export default {
    name: "home",
    components: { Scanner, LatestScans },
    data() {
      return {
        fullscreen: false,
        voucherCost: 0,
        cost: 0
      }
    },
    methods: {
      toggleFullscreen() {
        this.$refs["fullscreen"].toggle()
      },
      fullscreenChange(fullscreen) {
        this.fullscreen = fullscreen
      },
      voucherCostChanged(currentSchedule, half, portions) {
        let cost = parseInt(currentSchedule.coupons)
        
        if(half) { cost = 1 }

        let totalCost = (cost * portions)

        this.cost = totalCost
        this.voucherCost = this.cost
      },
      async scanComplete(testMode, person, currentSchedule, half, toGo, portions) {
        let sortedVouchers = sortBy(person.vouchers, [(voucher) => this.$DateTime.fromString(voucher.validUntil, "yyyy-MM-dd")])

        let currentBulk = sortedVouchers[0]
        let newAmount = (parseInt(currentBulk.amount) - this.cost)

        let logData = {
          who: person.id,
          what: "used_card",
          to: person.id,
          half: half,
          toGo: toGo,
          when: this.$DateTime.local().toISO(),
          portions: portions,
          cost: this.cost,
          voucherCost: this.voucherCost,
          newAmount: newAmount
        }

        if(newAmount < 0) {
          let nextBulk = sortedVouchers[1]
          let amountLeft = (this.cost - parseInt(currentBulk.amount))

          if(!nextBulk || (parseInt(nextBulk.amount) < amountLeft)) {
            let message = `Du har inte tillräckligt många kuponger.`
            let failedLog = Object.assign({}, logData, { failed: true, message: message })
            await firebase.firestore().collection(`logs`).add(failedLog)

            throw message
          } else {
            currentBulk.amount = 0
            nextBulk.amount = (parseInt(nextBulk.amount) - amountLeft)
          }
        } else {
          currentBulk.amount = newAmount
        }

        if(!testMode) {
          let batch = firebase.firestore().batch()
          let personRef = await firebase.firestore().collection(`people`).doc(person.id)
          
          batch.update(personRef, { vouchers: sortedVouchers })
          person.vouchers = sortedVouchers

          await firebase.firestore().collection(`logs`).add(logData)
          await batch.commit()
        }
      },
      countdownComplete(currentSchedule) {
        this.voucherCost = parseInt(currentSchedule.coupons)
      },
      signOut () {
        firebase.auth().signOut().then(() => {
          this.$router.replace({ name: "auth" })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home {
    background-color: #8bacbd;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .logo-wrapper {
    top: 20px;
    left: 20px;
    text-transform: uppercase;
    font-weight: 400;

    .logo-content {
      border-width: .2rem !important;
    }

    .logo {
      max-width: 53px;
    }
  }

  #signOut {
    bottom: 20px;
    right: 20px;
  }
</style>