<template>
  <span class="latest-scan" v-if="person && schedules.length > 0">
    <b>kl. {{ when }}</b><br>
    {{ person.fullName }}<br>
    <div v-if="failureLog">
      <small><strong>MISSLYCKADES:</strong> {{ log.message }}</small>
    </div>
    <div v-else>
      <span v-if="log.half">Halv, </span>
      <span v-if="log.toGo">Hämta, </span>
      <span v-if="portions">{{ portions }}, </span>
      <span>{{ couponsPhrase }}</span>
    </div>
  </span>
</template>

<script>
  import { firebase } from "@/initializers/firebase.js"

  export default {
    name: "latest-scan",
    props: {
      log: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        person: null,
        schedules: []
      }
    },
    computed: {
      failureLog() {
        return (typeof this.log.failed == "boolean" && this.log.failed == true)
      },
      when() {
        return this.$DateTime.fromISO(this.log.when).toFormat("HH:mm:ss")
      },
      portions() {
        let portions = 1
        if(this.log.portions) { portions = parseInt(this.log.portions) }

        return `${portions} ${portions == 1 ? 'portion' : 'portioner'}`
      },
      couponsPhrase() {
        return `${this.coupons} ${this.coupons == 1 ? "kupong" : "kuponger"}`
      },
      coupons() {
        let result = "n/a"
        if(this.schedules.length > 0) {
          let currentSchedule = this.schedules[0]
          let when = this.$DateTime.fromISO(this.log.when)
          let schedule = currentSchedule.days[when.weekday]
          let portions = 1

          if(this.log.portions) {
            portions = parseInt(this.log.portions)
          }

          if(this.log.half) {
            result = (1 * portions)
          } else {
            result = (parseInt(schedule.coupons) * portions)
          }
        }

        return result
      }
    },
    methods: {
      async getPerson() {
        let personGet = await firebase.firestore().collection(`people`).doc(this.log.to).get()
        this.person = await personGet.data()
      }
    },
    async mounted() {
      await this.getPerson()
      await this.$bind("schedules", firebase.firestore().collection(`schedules`).where("year", "==", this.$DateTime.local().year.toString()))
    },
    watch: {
      log: function(newValue, oldValue) {
        if(newValue.id != oldValue.id) {
          this.getPerson()
        }
      }
    }
  }
</script>