<template>
  <div class="latest-scans rounded px-4 py-2 pointer" :class="{ 'bg-danger': latestLogFailed, 'bg-white': !latestLogFailed }" v-if="logs.length > 0" @click="openScansModal">
    <b>Senaste</b> <latest-scan :log="latestLog"></latest-scan>

    <b-modal id="scans-modal" size="lg" body-class="p-0">
      <template v-slot:modal-title>Idag, {{ $DateTime.local().toLocaleString($DateTime.DATE_MED) }}</template>
      <template v-slot:modal-footer>
        <button type="button" class="btn btn-primary" @click="closeScansModal">Stäng</button>
      </template>

      <table class="table table-striped">
        <thead>
          <tr>
            <th class="border-top-0">Namn</th>
            <th class="border-top-0">Antal portioner</th>
            <th class="border-top-0">Kuponger</th>
            <th class="border-top-0 text-center">Halv?</th>
            <th class="border-top-0 text-center">Ta med?</th>
            <th class="border-top-0 text-right">Tid</th>
          </tr>
        </thead>
        <tbody>
          <scan-table-row v-for="log of sortedLogs" :key="log.id" :log="log"></scan-table-row>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase.js"
  import orderBy from "lodash/orderBy"

  import LatestScan from "@/frontend/components/latest-scan.vue"
  import ScanTableRow from "@/frontend/components/scan-table-row.vue"

  export default {
    name: "latest-scans",
    components: { LatestScan, ScanTableRow },
    data() {
      return {
        logs: []
      }
    },
    computed: {
      sortedLogs() {
        return orderBy(this.logs, ["when"], ["desc"])
      },
      latestLog() {
        return this.sortedLogs[0]
      },
      latestLogFailed() {
        if(this.latestLog) {
          return (typeof this.latestLog.failed == "boolean" && this.latestLog.failed == true)
        } else {
          return false
        }
      },
    },
    methods: {
      openScansModal() {
        this.$bvModal.show("scans-modal")
      },
      closeScansModal() {
        this.$bvModal.hide("scans-modal")
      }
    },
    async mounted() {
      let date = this.$DateTime.local().toFormat("yyyy-MM-dd")
      await this.$bind("logs", firebase.firestore().collection(`logs`).where("when", ">=", date).where("what", "==", "used_card"))
    }
  }
</script>

<style lang="scss" scoped>
  .latest-scans {
    position: absolute;
    bottom: 20px;
    left: 20px;
    min-width: 200px;
  }
</style>